import React from "react";
import { Form, Input, Button } from 'reactstrap';
import T from '../Translations';
import classnames from 'classnames'

import './Cta.scss';

const Cta = ({size, color, className}) => {
    // const handleSubmit = event => {
    //     console.log(event.target);
    //     Api.handleSubmit(event).then(result => {
    //         console.log(result);
    //     })
    // }

    return <div className={classnames("cta d-print-none", className, `color-${color}`)}>
        <Form inline className="" action="/prova/" type="GET">
            <Input style={{ flexGrow: 1 }} bsSize={size} type="email" required name="email" id="email" placeholder={T.yourEmail} className="" />
            <Button size={size} color={color} className="input-group-addon">{T.try}</Button>
        </Form>
    </div>
}

Cta.defaultProps = {
    color: "dark"
}

export default Cta;