import React from "react";
import classnames from 'classnames';

const Section = ({id, color, padding, margin, container, centered, bordered, boxed, background, fixed, children}) => (
	<section id={id} className={classnames(color, `padding-${padding} margin-${margin} background-${background} fixed-${fixed}`, { "text-center": centered, "bordered": bordered })}>
		{container ? <div className={classnames("container", { "boxed": boxed, "p-5": boxed })}>{children}</div> : children}
	</section>
)

Section.defaultProps = {
	color: "white",
	padding: "medium",
	margin: "none",
	background: "none",
	fixed: "none",
	container: true,
	centered: false
}

export default Section;
