import React from "react";

const webpExpression = /[.]webp$/;

const Image = ({ src, fallback, fallbackExtension, alt, width, height, maxWidth, maxHeight, ...props}) => {
  if (!src) return null;

  if (fallback && webpExpression.test(src)) {
    const fallbackSrc = src.replace(webpExpression, fallbackExtension);
    return <picture>
      <source srcSet={src} type="image/webp" />
      <source srcSet={fallbackSrc} type="image/jpeg" />
      <img src={fallbackSrc} alt={alt} {...props} />
    </picture>
  }

  return <img style={{ width: width, height: height, maxWidth: maxWidth, maxHeight: maxHeight }} {...props} alt={alt} src={src} />
};

Image.defaultProps = {
  fallbackExtension: ".png"
};

export default Image;
