import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

import Text from './Text';
import Api from '../Api';

import Translations from '../Translations';
import "./Contact.scss";
import useSearch from "./useSearch";

const Contact = ({ heading, body }) => {
	// console.log("Contact", search);
	const search = useSearch();

	const [sending, setSending] = useState(false);
	const [status, setStatus] = useState(0);
	const handleSubmit = event => {
		setSending(true);
		Api.handleSubmit(event).then(result => {
			setSending(false);
			setStatus(1);
			console.log("handleSubmit", event.target, result);
		}, error => {
			setSending(false);
			setStatus(-1);
		})
	}

	return <div className="contact">
		<Text heading={heading} body={body} />
		<StatusForm disabled={sending} status={status} onSubmit={handleSubmit} action="https://bngaged.io/api/external/register/contact">
			<LabelledInput autoFocus name="name" defaultValue={search.name} label={Translations.name} placeholder={Translations.yourName} />
			<LabelledInput name="phone" defaultValue={search.phone} type="tel" label={Translations.phone} placeholder={Translations.yourPhone} />
			<LabelledInput name="email" defaultValue={search.email} type="email" label={Translations.email} placeholder={Translations.yourEmail} />
			<LabelledInput name="message" defaultValue={search.message} type="textarea" label={Translations.message} placeholder={Translations.yourMessage} />
			<Button size="lg" color={!status ? "primary" : status>0 ? "success" : "error" } type="submit">{sending ? Translations.sending : status>0 ? <><b className="fas fa-check"/> {Translations.sent}</> : Translations.send }</Button>
		</StatusForm>
	</div>
}

export const LabelledInput = ({ autoFocus, name, label, feedback, defaultValue, ...props }) => <FormGroup>
	<Label for={name}>{label}</Label>
	<Input ref={el => el && autoFocus && setTimeout(function () { el.focus() }, 200)} id={name} name={name} defaultValue={defaultValue || ""} {...props} />
	{feedback ? <p className="font-weight-italic text-info">{feedback}</p> : null}
</FormGroup>

export const StatusForm = ({status, action, inline, disabled, children, successHeader, successMessage, errorHeader, errorMessage, onSubmit}) => {
	const form = <Form action={action} inline={inline} disabled={disabled} onSubmit={onSubmit}>
		{children}
	</Form>;

	if (status > 0)
		return <>
			{form}
			<div className="mt-4">
				<h3>{successHeader}</h3>
				<p>{successMessage}</p>
			</div>
		</>
	if (status < 0)
		return <>
			{form}
			<div className="mt-4">
				<h3>{errorHeader}</h3>
				<p>{errorMessage}</p>
			</div>
		</>

	return form;
}

StatusForm.defaultProps = {
	successHeader: Translations.successHeader, 
	successMessage: Translations.successMessage, 
	errorHeader: Translations.errorHeader, 
	errorMessage: Translations.errorMessage
}

export default Contact;