import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import jscookie from "js-cookie";
import Header from './Header';
import Footer from './Footer';
import 'typeface-poppins';
import "./Layout.scss"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  const [scrolled, scrolledSet] = useState(jscookie.get("__hs_opt_out") === "no");
  
  useEffect(() => {
    if (scrolled)
      addHubspot();
  });

  return (
    <Scrollable onScrollDown={handleScrollDown}>
      <Header />
      <main>{children}</main>
      <Footer className={scrolled ? "" : "beginner"} />
    </Scrollable>
  )

  function handleScrollDown() {
    if (scrolled)
      return;

	addHubspot();
	addMailchimp();
  }

  function addHubspot(){
    scrolledSet(true);

    const trackingCode = "5132031";

    jscookie.set("__hs_opt_out", "no");

    addScript({ type: "text/javascript", id: "hs-script-loader", async: true, defer: true, src: `//js.hs-scripts.com/${trackingCode}.js` }, `
      var _hsq = window._hsq = window._hsq || [];
      _hsq.push(['setPath', window.location.pathname + window.location.search + window.location.hash]);
      if (window.doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack || 'msTrackingProtectionEnabled' in window.external) {
        if (window.doNotTrack == "1" || navigator.doNotTrack == "yes" || navigator.doNotTrack == "1" || navigator.msDoNotTrack == "1" || window.external.msTrackingProtectionEnabled()) {
          _hsq.push(['doNotTrack']);
        }
      }
	`);
	
  }

  function  addMailchimp(){
	  addScript({ type: "text/javascript", id: "mcjs", async: true, defer: true }, `
!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/5d4092a7280bf32a11e967dbe/840d49e8d133b92cdc2e0ac7b.js"); 
	  `)
  }

  function addScript(attributes, text, callback) {
    var s = document.createElement('script');
    for (var attr in attributes) {
        s.setAttribute(attr, attributes[attr] ? attributes[attr] : null)
    }
    s.innerHTML = text;
    s.onload = callback;
    document.body.appendChild(s);
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export class Scrollable extends React.Component {
  state = { x: 0, y: 0 };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = event => {
    if (window.scrollY > 100) {
      document.body.classList.add("scroll");
      this.props.onScrollDown();
    }
    else
      document.body.classList.remove("scroll");
  };

  render() {
    return this.props.children;
  }
}

Scrollable.defaultProps = {
  onScrollDown: () => {}
}

export default Layout
