import React from "react"
import Cta from './Cta';
import {VideoButton} from './Video';
import './Hero.scss';
import Body from "./Body";

const Hero = ({ heading, subHeading, body, cta, videoButton, background }) => {
	return (
		<div className="content hero position-relative overflow-hidden text-center" style={{ background: background }}>
			<div className="hero-container">
				<h1 className="" dangerouslySetInnerHTML={{ __html: heading }}></h1>
				{subHeading ? <p className="sub-heading" dangerouslySetInnerHTML={{ __html: subHeading }}></p> : null}
				<Body content={body} />
				{cta ? <Cta className="mt-4 mb-2" color="danger" {...cta} /> : null }
				{videoButton ? <VideoButton className="mt-4 mb-2" color="danger" {...videoButton} /> : null }
			</div>
			{/* <div className="product-device shadow-sm d-none d-md-block"></div>
			<div className="product-device product-device-2 shadow-sm d-none d-md-block"></div> */}
		</div>
	)
}
export default Hero;