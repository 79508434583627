import React from "react";
import classnames from 'classnames';
import Sections from './Sections';
import Page from "../components/Page";

import "./Footer.scss";
import {page, sections} from "../data/footer.json";

export const Footer = ({ className }) => <footer className={classnames("page-footer font-small special-color-dark", className)}>
	<Page {...page} />
	<Sections sections={sections} />
</footer>

export default Footer;