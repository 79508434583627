import React from "react";
import "./Intro.scss";
import Body from "./Body";
import Image from './ImageFallback';

const Intro = ({ heading, imageSrc, imageAlt, body }) => heading || body 
	? <div className="intro">
		{imageSrc ? <Image src={imageSrc} alt={imageAlt} /> : null}
		{heading ? <h1 dangerouslySetInnerHTML={{ __html: heading }}></h1> : null}
		<Body content={body} />
	</div>
	: null;

export default Intro;