import React from "react"

import Section from "./Section";
import Seo from "./Seo2";
import Hero from "./Hero";
import Hero2 from "./Hero2";
import Text from './Text';
import Intro from './Intro';
import Cards from './Cards';
import LogoCarousel from './LogoCarousel';
import Spotlight from './Spotlight';
import Cta from './Cta';
import Pointer from './Pointer';
import BlogList from './BlogList';
import Contact from './Contact';
import FreeTest from './FreeTest';
import LinkButton from './LinkButton';
import Try from './Try';
import Newsletter from './Newsletter';
import {VideoButton, Video} from './Video';

export const Templates = {
	"Hero": Hero,
	"Hero2": Hero2,
	"SEO": Seo,
	"Seo": Seo,
	"Text": Text,
	"Intro": Intro,
	"Cards": Cards,
	"LogoCarousel": LogoCarousel,
	"Spotlight": Spotlight,
	"Cta": Cta,
	"Pointer": Pointer,
	"BlogList": BlogList,
	"Contact": Contact,
	"FreeTest": FreeTest,
	"LinkButton": LinkButton,
	"Try": Try,
	"Newsletter": Newsletter,
	"VideoButton": VideoButton,
	"Video": Video,
	"Empty": props => null,
};

export const Sections = ({ sections }) => sections
	? sections.map((s, i) => {
		// console.log(s.template);
		const component = Templates[s.template];
		if (!component)
			return `Missing component ${s.template}`;

		const element = React.createElement(component, s);
		if (s.section)
			return <Section key={i} {...s.section}>{element}</Section>
		else
			return <div key={i}>{element}</div>;
	})
	: null;

export default Sections;