import React from "react";
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';
import BigDot from './BigDot';
import Image from './ImageFallback';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faStethoscope, faCheck, faCircle } from '@fortawesome/free-solid-svg-icons'
// import { fas } from '@fortawesome/free-solid-svg-icons'
import "./Cards.scss";
import Body from "./Body";

const Cards = ({ heading, body, html, dotted, cards, columns }) => <>
	{heading ? <h2 dangerouslySetInnerHTML={{ __html: heading }} /> : null}
	<Body content={body} />
	{html ? <div dangerouslySetInnerHTML={{ __html: html }} /> : null}
	<div className="row cards">
		{cards.map((c, i) => <div key={i} className={classnames(`col col-12 col-md-${Number.isFinite(columns) ? Math.floor(12 / columns) : getCols(cards.length)}`, { "mt-2": heading || body, "mt-2": !heading && !body })}>
			{c.icon ? <b className={`icon fas fa-${c.icon}`}></b> : null}
			{dotted ? <BigDot number={i + 1} /> : null}
			{c.image ? <div className={"image " + (c.className || "")}><Image {...c.image} fallback /></div> : null}
			{c.imageUrl ? <div className="image mb-3"><Image src={c.imageUrl} fallback alt={c.imageAlt} /></div> : null}
			{c.heading ? <h4 className="mb-3" style={{ verticalAlign: "middle" }} dangerouslySetInnerHTML={{ __html: c.heading }} /> : null}
			{/* <FontAwesomeIcon icon={["fab", "apple"]} mask={faCircle} color="red" size="lg" /> */}
			{c.markdown ? <ReactMarkdown source={c.markdown} /> : null}
			<Body content={console.log("C", c.heading, c.body, c.html, c) || c.body || c.html} />
		</div>)}
	</div>
</>

function getCols(count) {
	if (count >= 5)
		return 4;

	return Math.floor(12 / count);
}

export default Cards;