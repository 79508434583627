import React from "react";
import "./LogoCarousel.scss";
import Body from "./Body";

const LogoCarousel = ({ heading, body, html, dotted, logos, columns }) => <>
	{heading ? <h2 dangerouslySetInnerHTML={{ __html: heading }} /> : null}
	<Body content={body} />
	{html ? <div dangerouslySetInnerHTML={{ __html: html }} /> : null}
	<div className="customer-logos">
		{logos.map((l, i) => <img key={i} alt={l.alt} className="customer-logo" style={{ height: l.imageHeight }} src={l.imageSrc} />)}
	</div>
</>

export default LogoCarousel;