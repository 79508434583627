const translations = {
	yourEmail: "Din e-post här",
	try: "Prova på",
	notFound: "404: Sidan kunde inte hittas",
	notFoundBody: "Du har hamnat på en sida som inte finns kvar.",
	toStartPage: "Till startsidan",
	name: "Namn",
	yourName: "Vad heter du?",
	phone: "Telefonnummer",
	yourPhone: "Ditt telefonnummer",
	email: "E-post",
	companyEmail: "E-post på jobbet",
	yourCompanyEmail: "Din e-post på jobbet",
	message: "Meddelande",
	yourMessage: "Skriv några rader om ditt ärende",
	yourTryMessage: "Skriv om du har några särskilda frågor redan nu",
	send: "Skicka",
	sending: "Skickar...",
	sent: "Skickat",
	subscribe: "Anmäl",
	company: "Företag",
	yourCompany: "Var jobbar du?",
	employees: "Antal anställda",
	haveCompanyEmail: "Har du en jobbmail?",
	successHeader: "...och iväg!",
	successMessage: "Ditt meddelande är på väg till oss. Vi hörs snart.",
	successMessageTry: "Dina uppgifter är på väg till oss. Vi hör av oss snart och stämmer av.",
	errorHeader: "Ajdå!",
	errorMessage: "Något gick snett och det gick inte att skicka. Testa gärna igen eller kontakta oss via telefon eller mail.",
	video: "Hur funkar det?"
}
export default translations;