import React, { useState } from "react";
import { FormGroup, Label, Input, Button } from 'reactstrap';

import { LabelledInput, StatusForm } from './Contact';
import Text from './Text';
import Api from '../Api';
import Translations from '../Translations';
import useSearch from "./useSearch";

const Try = ({ heading, body }) => {
	// console.log("Contact", search);
	const search = useSearch();

	const [sending, setSending] = useState(false);
	const [status, setStatus] = useState(0);
	const [email, setEmail] = useState(search.email || "");
	const handleSubmit = event => {
		setSending(true);
		Api.handleSubmit(event).then(result => {
			setSending(false);
			setStatus(1);
			console.log("handleSubmit", event.target, result);
		}, error => {
			setSending(false);
			setStatus(-1);
		})
	}

	const emailFeedback = socialDomains.find(d => email.indexOf(d) >= 0)
		? Translations.haveCompanyEmail
		: null;

	return <div className="try">
		<Text heading={heading} body={body} />
		<StatusForm disabled={sending} status={status} onSubmit={handleSubmit} action="https://bngaged.io/api/external/register/try" successMessage={Translations.successMessageTry}>
			<LabelledInput autoFocus name="name" defaultValue={search.name} label={Translations.name} placeholder={Translations.yourName} />
			<LabelledInput name="phone" defaultValue={search.phone} type="tel" required={true} label={Translations.phone} placeholder={Translations.yourPhone} />
			<LabelledInput name="email" defaultValue={search.email} type="email" required={true} label={Translations.companyEmail} placeholder={Translations.yourCompanyEmail}
				onKeyUp={event => setEmail(event.target.value)} feedback={emailFeedback} />
			<LabelledInput name="company" defaultValue={search.company} label={Translations.company} placeholder={Translations.yourCompany} />
			<FormGroup>
				<Label for="employees">{Translations.employees}</Label>
				<Input id="employees" name="employees" type="select">
					<option></option>
					<option>0-100</option>
					<option>100-500</option>
					<option>500-1000</option>
					<option>1000+</option>
				</Input>
			</FormGroup>
			<LabelledInput name="message" defaultValue={search.message} type="textarea" label={Translations.message} placeholder={Translations.yourTryMessage} />

			<p>{Translations.beforeSend}</p>
			<Button size="lg" color={!status ? "primary" : status > 0 ? "success" : "error"} type="submit">{sending ? Translations.sending : status > 0 ? <><b className="fas fa-check" /> {Translations.sent}</> : Translations.send}</Button>
		</StatusForm>
	</div>
}

const socialDomains = ["@hotmail", "@gmail", "@outlook", "@yahoo", "@live", "@msn", "@icloud", "@telia."];

export default Try;