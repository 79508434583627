
import React from "react";

const Body = ({ content }) => Array.isArray(content) ? content.map((p, i) => <Text key={i} content={p} />) : <Text content={content} />

const Text = ({ content }) => content 
	? content[0] === "<" 
		? <div dangerouslySetInnerHTML={{ __html: content }}></div> 
		: <p dangerouslySetInnerHTML={{ __html: content }}></p>
	: null;

export default Body;