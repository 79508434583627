import React from "react";
import LinkButton from './LinkButton';
import "./Text.scss";
import Body from "./Body";
import Image from './ImageFallback';

const Text = ({ image = {}, imageUrl, imageAlt, imagePlacement, imageFallbackExtension, imageBottom, heading, body, html, texts, linkButton }) => {
	// console.log("T", heading, ", b=", body, html, texts);
	image = { src: imageUrl || image.url, alt: imageAlt, placement: imagePlacement || "left", fallbackExtension: imageFallbackExtension, bottom: imageBottom, ...image };

	return <div className={"text" + (image.src ? ` text-image-${image.placement}` : "")}>
		{image.src ? <Image {...image} /> : null}
		<div>
			{heading ? <h2 dangerouslySetInnerHTML={{ __html: heading }}></h2> : heading}

			<Body content={body || html || texts} />
			{linkButton ? <LinkButton {...linkButton} /> : null}
		</div>
	</div>
	// return image
	// 	? <PlacedImage {...image} textBlock={textBlock} />
	// 	: imageUrl
	// 		? <PlacedImage textBlock={textBlock} url={imageUrl} alt={imageAlt} placement={imagePlacement} fallbackExtension={imageFallbackExtension} bottom={imageBottom} />
	// 		: textBlock;
}

const PlacedImage = ({ textBlock, url, alt, placement, fallbackExtension, bottom, height, columns = 6 }) => {
	if (placement === "right") {
		return <div className="text row">
			<div className={`col col-12 col-md-${12 - columns}`}>{textBlock}</div>
			<div className={`col col-12 col-md-${columns}`}><Image alt={alt} className={bottom ? "bottom" : ""} src={url} fallback fallbackExtension={fallbackExtension} /></div>
		</div>
	}
	if (placement === "float-left") {
		return <div className="text">
			<Image alt={alt} className={bottom ? "bottom" : ""} src={url} fallback fallbackExtension={fallbackExtension} style={{ float: "left" }} />
			{textBlock}
		</div>
	}
	if (placement === "float-right") {
		return <div className="text">
			<Image alt={alt} className={bottom ? "bottom" : ""} src={url} fallback fallbackExtension={fallbackExtension} style={{ float: "right" }} />
			{textBlock}
		</div>
	}

	return <div className="text row">
		<div className={`col col-12 col-md-${columns}`}><Image style={{ height: height }} alt={alt} className={bottom ? "bottom" : ""} src={url} fallback fallbackExtension={fallbackExtension} /></div>
		<div className={`col col-12 col-md-${12 - columns}`}>{textBlock}</div>
	</div>
}

PlacedImage.defaultProps = {
	placement: "left"
}

export default Text;