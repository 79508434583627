import React, { useState } from "react";
import { Button } from 'reactstrap';
import T from '../Translations';
import classnames from 'classnames'

import './Video.scss';

export const VideoButton = ({size, color, className, src}) => {
	const [visible, visibleSet] = useState(false);
	const handleToggleVisibility = () => {
		const nextVisible = !visible;
		visibleSet(nextVisible);

		if (!nextVisible) {
			const player = document.getElementById("player");
			player.classList.remove("show");
		}
		else
			setTimeout(function(){
				const player = document.getElementById("player");
				player.scrollIntoView({ behavior: "smooth", block: "center" });
				player.classList.add("show");
			}, 50);
	}

	return <div className={classnames("video d-print-none", className, `color-${color}`)}>
			<Button size={size} color={color} onClick={handleToggleVisibility}><b className={visible ? "fa fa-stop" : "fa fa-film"}/> {T.video}</Button>
			{visible 
				? <>
					<div id="player" className="video-modal">
						<button onClick={handleToggleVisibility} type="button" className="close" aria-label="Close"><span aria-hidden="true">×</span></button>
						<iframe title="Explainer video" src={src} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
					</div>
					<div onClick={handleToggleVisibility} className="modal-backdrop fade show"></div>
				</>
				 : null
			}
			{/* <Modal isOpen={visible} toggle={handleToggleVisibility}>
				<ModalHeader toggle={handleToggleVisibility}></ModalHeader>
				{visible ? <iframe width="560" height="315" src={src} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> : null}
			</Modal> */}
		</div>
}

VideoButton.defaultProps = {
    color: "dark"
}

export const Video = ({heading, className, src}) => <div id="player" className="video-inline">
	{heading ? <h2 dangerouslySetInnerHTML={{ __html: heading }}></h2> : heading}
	<iframe title="Explainer video" className={className} src={src} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
</div>

VideoButton.defaultProps = {
	className: ""
}

export default VideoButton;