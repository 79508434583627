import PropTypes from "prop-types";
import React, { useState } from "react";
import { NavbarBrand, NavbarToggler, Collapse, Nav, NavLink } from "reactstrap";

import Cta from './Cta';
import "./Header.scss";

import { logo, links, cta } from '../data/navigation.json';

const Header = () => {
  const [collapsed, setCollapsed] = useState(undefined);

  return (
    <header id="header">
       <nav className="navbar-expand-lg bd-navbar fixed-top navbar navbar-light">
         <div className="container">
          <NavbarBrand href={logo.url} className="mr-auto brand">
            {/* <img src={logoUrl} alt="logo" /> <span>{logo.text}</span> */}
            <svg id="logo" xmlns="http://www.w3.org/2000/svg" width="128px" height="64px" version="1.1" viewBox="3850 600 500 1500">
              <g id="logo-vertical">
                <g className="logo-symbol">
                  <path className="logo-red" d="M4502 1037c0,222 -180,403 -403,403 -222,0 -403,-180 -403,-403 0,-222 180,-403 403,-403 222,0 403,180 403,403z"/>
                  <g>
                    <path className="logo-white" d="M4007 1026l0 -1c42,-10 61,-42 61,-77 0,-50 -29,-90 -99,-90l-138 0 0 34 131 0c41,0 70,14 70,57 0,48 -32,64 -82,64l-118 0 0 34 136 0c43,0 75,15 75,66 0,49 -36,68 -84,68l-127 0 0 34 133 0c55,0 118,-28 118,-102 0,-46 -28,-80 -75,-87z"/>
                    <path className="logo-white" d="M4192 1026l0 -1c-42,-10 -61,-42 -61,-77 0,-50 29,-90 99,-90l138 0 0 34 -131 0c-40,0 -69,14 -69,57 0,48 32,64 82,64l118 0 0 34 -136 0c-43,0 -75,15 -75,66 0,49 36,68 84,68l127 0 0 34 -133 0c-55,0 -118,-28 -118,-102 0,-46 28,-80 75,-87z"/>
                  </g>
                </g>
                <g className="logo-text">
                  <path className="logo-dark" d="M2649 1526l203 0c70,0 99,39 99,90 0,34 -19,67 -61,76l0 1c47,7 75,42 75,87 0,74 -63,102 -118,102l-198 0 0 -357zm37 155l0 0 146 0c50,0 82,-16 82,-63 0,-44 -29,-57 -70,-57l-159 0 0 121zm0 168l0 0 155 0c48,0 84,-19 84,-68 0,-51 -32,-66 -75,-66l-164 0 0 134z"/>
                  <polygon className="logo-dark" points="3164,1526 3448,1526 3448,1560 3201,1560 3201,1681 3439,1681 3439,1715 3201,1715 3201,1849 3452,1849 3452,1883 3164,1883 "/>
                  <path className="logo-dark" d="M3759 1715c0,-51 -32,-69 -78,-69 -72,0 -105,37 -105,96l0 141 -37 0 0 -260 37 0 0 51 1 0c11,-27 50,-59 107,-59 86,0 112,45 112,107l0 161 -37 0 0 -167z"/>
                  <path className="logo-dark" d="M4121 1623l37 0 0 250c0,96 -77,120 -146,120 -118,0 -130,-56 -133,-84l34 0c5,28 13,56 100,56 68,0 107,-32 107,-94l0 -46 -1 0c-14,27 -48,59 -115,59 -66,0 -138,-34 -138,-135 0,-69 44,-132 142,-132 50,0 93,19 111,58l1 0 0 -50zm-110 23l0 0c-65,0 -107,38 -107,101 0,76 47,104 110,104 61,0 108,-38 108,-104 0,-54 -38,-101 -111,-101z"/>
                  <path className="logo-dark" d="M4240 1703c5,-54 35,-88 124,-88 87,0 119,27 119,77l0 136c0,21 6,29 20,29 7,0 13,-1 20,-1l0 25c-7,2 -19,4 -31,4 -38,0 -39,-26 -40,-46l-1 0c-12,17 -36,52 -121,52 -45,0 -101,-20 -101,-78 0,-71 72,-78 129,-81 81,-5 91,-9 91,-35 0,-37 -25,-51 -86,-51 -58,0 -86,16 -89,57l-34 0zm209 40l0 0c-17,11 -42,12 -116,19 -43,4 -68,17 -68,48 0,40 35,52 75,52 50,0 109,-27 109,-80l0 -39z"/>
                  <path className="logo-dark" d="M4830 1623l37 0 0 250c0,96 -77,120 -146,120 -118,0 -130,-56 -133,-84l34 0c5,28 13,56 100,56 68,0 107,-32 107,-94l0 -46 -1 0c-14,27 -48,59 -115,59 -66,0 -138,-34 -138,-135 0,-69 44,-132 142,-132 51,0 94,19 111,58l1 0 0 -50zm-110 23l0 0c-65,0 -107,38 -107,101 0,76 48,104 110,104 61,0 108,-38 108,-104 0,-54 -37,-101 -111,-101z"/>
                  <path className="logo-dark" d="M5209 1800c-15,46 -54,91 -140,91 -107,0 -141,-74 -141,-138 0,-72 44,-138 141,-138 67,0 146,29 146,147l-251 0c0,54 38,98 104,98 62,0 90,-20 103,-60l37 0zm-30 -66l0 0c-10,-64 -58,-88 -107,-88 -47,0 -98,25 -107,88l214 0z"/>
                  <path className="logo-dark" d="M5514 1831l-1 0c-10,22 -43,60 -106,60 -106,0 -141,-74 -141,-138 0,-72 44,-138 141,-138 50,0 89,27 106,60l1 0 0 -152 37 0 0 360 -37 0 0 -52zm-107 -185l0 0c-58,0 -104,41 -104,107 0,52 29,107 104,107 61,0 109,-39 109,-107 0,-53 -34,-107 -109,-107z"/>
                  <path className="logo-red" d="M3098 1708c0,17 -14,31 -31,31 -17,0 -31,-14 -31,-31 0,-17 14,-31 31,-31 17,0 31,14 31,31z"/>
                </g>
              </g>
            </svg>
          </NavbarBrand>
          <NavbarToggler onClick={event => setCollapsed(!collapsed)} className="mr-2" />
          <Collapse isOpen={collapsed} navbar>
            <Nav navbar className="navbar-right ml-5">
              {links.map((n, i) => <NavLink key={i} href={n.url}>{n.icon ? <b className={`fas fa-${n.icon}`}></b> : null} {n.text}</NavLink>)}
            </Nav>
          </Collapse>
          <Cta size="sm" className="d-none d-xl-block" {...cta} />
          </div>
        </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
