import React from "react"
import LinkButton from './LinkButton';
import Body from "./Body";
import Image from './ImageFallback';

const Spotlight = ({ image = {}, imageSrc, imageAlt, imageSize, imageHeight = "auto", imageWidth, imagePosition = "left", imageColumns = 6, heading, body, linkButton }) => {
	image = {
		src: imageSrc,
		alt: imageAlt,
		size: imageSize,
		height: imageHeight,
		width: imageWidth,
		position: imagePosition,
		columns: imageColumns,
		...image
	}

	const renderedImage = <div className={`col col-12 col-md-${image.columns} text-center`}><SpotlightImage {...image} /></div>;

	return <div className="row spotlight">
		{image.position == "left" ? renderedImage : null}
		<div className={`col col-12 col-md-${image.src ? (12 - image.columns) : 12}`}>
			<h3 className="mt-4">{heading}</h3>
			<Body content={body} />
			{linkButton ? <div><LinkButton {...linkButton} /></div> : null}
		</div>
		{image.position == "right" ? renderedImage : null}
	</div>
}

function SpotlightImage({ src, alt, width, maxWidth = "100%", height = "auto", maxHeight, className = "" }) {
	return <Image src={src} fallback alt={alt} style={{ width: width, maxWidth: maxWidth, height: height, maxHeight: maxHeight }} className={className} />
}

export default Spotlight;