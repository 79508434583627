import React from "react"
import { useStaticQuery, graphql } from "gatsby";

import "./BlogList.scss";

const BlogList = () => {
	
	const { allMarkdownRemark } = useStaticQuery(graphql`
	{
	  allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date]}) {
		edges {
		  node {
			excerpt
			frontmatter {
			  path
			  date
			  title
			  image
			}
		  }
		}
	  }
	}
	`);
	
	return allMarkdownRemark.edges.map(e => <a key={e.node.frontmatter.path} className="list-item boxed" href={e.node.frontmatter.path}>
		{e.node.frontmatter.image ? <div className="image" style={{ backgroundImage: `url(${e.node.frontmatter.image})` }} /> : null}
		<div className="p-5">
			<h2 dangerouslySetInnerHTML={{ __html: e.node.frontmatter.title }}></h2>
			<p>{e.node.excerpt}</p>
		</div>
	</a>);
}
  
export default BlogList;