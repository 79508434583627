import React, { useState } from "react";
import { Input, Button } from 'reactstrap';

import Text from './Text';
import Api from '../Api';

import { StatusForm } from './Contact';

import Translations from '../Translations';
import './Cta.scss';
import useSearch from "./useSearch";

const Newsletter = ({ heading, body }) => {
	// console.log("Newsletter", search);
	const search = useSearch();

	const [sending, setSending] = useState(false);
	const [status, setStatus] = useState(0);
	const handleSubmit = event => {
		setSending(true);
		Api.handleSubmit(event).then(result => {
			setSending(false);
			setStatus(1);
			console.log("handleSubmit", event.target, result);
		}, error => {
			setSending(false);
			setStatus(-1);
		})
	}

	return <div className="newsletter cta color-danger">
		<Text heading={heading} body={body} />
		<StatusForm className="row" inline disabled={sending} status={status} onSubmit={handleSubmit} action="https://bngaged.io/api/external/register/newsletter">
			<Input className="col col-12 col-md-8" name="email" defaultValue={search.email} type="email" label={Translations.email} placeholder={Translations.yourEmail} />
			<Button className="col col-12 col-md-4 input-group-addon" color="danger" type="submit">{sending ? Translations.sending : status > 0 ? <><b className="fas fa-check" /> {Translations.sent}</> : Translations.subscribe}</Button>
		</StatusForm>
	</div>
}

export default Newsletter;