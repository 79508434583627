class Api {
	handleSubmit = event => {
		event.preventDefault();

		var data = {};
		for(var i = 0; i < event.target.length; i++) {
			console.log(i, event.target[i].name, event.target[i].value);
			if (event.target[i].name)
				data[event.target[i].name] = event.target[i].value;
		}

		return this.post(event.target.action, {}, data);
	}
  	post = (path, ignored, data) => {
		return fetch(path, {
			method: 'POST',
			body: JSON.stringify(data || null),
			mode: 'cors',
			headers: {
				'Accept': 'application/json, text/plain, */*',
				'Content-Type': 'application/json'
			}
		})
	}
}
export default new Api();