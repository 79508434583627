import { useEffect, useState } from "react";
import querystring from 'querystring';

function useSearch(){
    const [query, querySet] = useState({})
    useEffect(function () {
        querySet(querystring.parse(window.location.search.slice(1)));
    }, []);
    return query;
}
export default useSearch;